.order-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: unset;
}

.modal-close-button {
  position: absolute;
  top: 40px;
  right: 40px;
  width: fit-content !important;
  padding: 0 !important;
}

.order-modal :focus-visible {
  outline: unset !important;
}

.modal-stack {
  background-color: #fff;
  max-width: unset;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: auto;
}

.text-container {
  width: 50%;
  background-color: #F2F5F7;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
}

.text-stack {
  max-width: 620px;
  margin: auto 50px;
}

.text-stack > :is(h2, h6) {
  text-align: center;
}

.form-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 80px 0;
}

.form-stack {
  margin: auto 50px;
}

.order-modal-form-stack {
  max-width: 500px;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
}

.order-modal-form {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}