.features-heading {
  text-align: center;
  white-space: pre-line;
  max-width: 910px;
  margin-bottom: 100px;
}

.features-heading-description {
  max-width: 1100px;
  width: 100%;
}

.features-cards {
  justify-content: center;
}

.features-card-group {
  justify-content: center;
}

.features-card {
  max-width: 260px;
  width: 100%;
  padding: 0 20px;
}