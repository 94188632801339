.full-width-background {
    width: 100%;
    background-color: #F2F5F7;
}

.container {
    flex-wrap: nowrap !important;
    height: 600px;
}

.content {
    width: 50%;
}

.image-container {
    width: 40%;
    position: relative;
}

.hero-image {
    position: absolute;
    right: 0;
    height: 100%;
}

.text-container {
    width: 60%;
    display: grid;
    height: fit-content;
    row-gap: 24px;
    margin: auto 0;
    padding: 0 40px;
}

.text-container .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.text-container .actions a {
    text-align: center;
}