.plans {
    display: flex;
    gap: 48px;
}

.plan-container {
    display: grid;
    grid-template-columns: auto;
    row-gap: 24px;
    max-width: 250px;
    width: 100%;
    border: 1px solid #C2D1D9;
    padding: 45px;
    align-content: space-between;
}

.plan-container button {
    height: 40px;
}

.plan-container-disabled {
    opacity: 0.65;
}

.plan-container-disabled button {
    color: #333 !important;
}

.description-container {
    display: grid;
    grid-template-columns: auto;
    row-gap: 8px;
}

.bulletpoint {
    display: grid;
    grid-template-columns: 40px auto;
}

.price {
    display: flex;
    align-items: center;
    column-gap: 16px;
}