.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 150px;
    border-top: 1px solid #C2D1D9;
}

.content {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
    gap: 16px;
}