.app {
  width: 100%;
}

.sections {
  width: 100%;
}

.sections > div > div ~ div {
  margin-top: 130px;
  padding-top: 70px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
}

.App-text {
  white-space: pre-line;
}

.App-link {
  color: #61dafb;
}

.section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.5rem;
}

/* App.css */
.hero {
  display: flex;
  flex-direction: column; /* Stack elements on small screens */
  justify-content: center;
  align-items: center;
  padding: 20px; /* Add spacing around the content */
}

.hero-left img {
  width: 100%;
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  height: auto;
  margin-bottom: 20px; /* Add space between image and content */
}

.hero-right {
  text-align: left;
}

/* Set a max width of 1440px for the content */
#start .start-content {
  width: 100%;
  padding: 0 20px; /* Add padding for spacing on both sides */
  text-align: left;
}

.nav-link {
  color: #000;
  margin-right: 30px;
  text-decoration: none;
}

.active-scroll-spy {
  background-color: yellow;
  outline: 20px solid #fff;
  margin: 0 20px;
  text-decoration: none;
}