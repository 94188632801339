.container-background {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #F2F5F7;
}

.content {
  display: flex;
  row-gap: 16px;
  max-width: 946px;
  width: 100%;
  justify-content: space-between;
  margin: 0 24px;
}

.buttons {
  margin: 40px 0;
  column-gap: 40px;
}

.phoneCaruosel {
  width: 374px;
  height: 465px;
  margin: 0 80px;
}