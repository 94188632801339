.benefit-heading {
  text-align: center;
  white-space: pre-line;
  max-width: 850px;
  margin-bottom: 100px;
}

.benefit-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
}

.benefit-card-stack {
  justify-content: center;
  max-width: 1440px;
  width: 100%;
}

.benefit-card {
  display: flex;
  justify-content: center;
  max-width: 720px;
  width: 100%;
  margin: auto 0;
}

.benefit-textbox-stack a {
  width: 100%;
}

.benefit-textbox-stack {
  max-width: 540px;
  padding: 48px 0;
}